import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import Axios, { AxiosError } from 'axios'
import { PaginatedList } from 'modules/common/types/utils'
import querystring from 'query-string'
import { UseQueryOptions } from 'react-query'
import {
  Document,
  EnumDocumentStatus,
  EnumDocumentType,
} from '../types/document'
import { EnumInvoicingQueryKeys } from './keys'

type DocumentListQueryProps = {
  type?: EnumDocumentType
  status?: EnumDocumentStatus
  customerId?: string
  transactionLinked?: boolean
}

const fetchDocumentsList = async (
  query: DocumentListQueryProps | undefined,
  accessToken: string
) => {
  const apiUrl = querystring.stringifyUrl({
    url: `${process.env.NEXT_PUBLIC_API_URL}/v1/invoicing/documents`,
    query,
  })

  const { data } = await Axios.get<PaginatedList<Document>>(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return data
}

export const useDocumentsListQuery = <TData = PaginatedList<Document>>(
  query?: DocumentListQueryProps,
  queryOptions?: UseQueryOptions<PaginatedList<Document>, AxiosError, TData>
) => {
  return useQueryWithAuth<PaginatedList<Document>, AxiosError, TData>(
    // The key must only be `[EnumInvoicingQueryKeys.DOCUMENTS_LIST]` when there is no query params
    [EnumInvoicingQueryKeys.DOCUMENTS_LIST, ...(query ? [query] : [])],
    async (context, accessToken) => {
      return fetchDocumentsList(query, accessToken)
    },
    queryOptions
  )
}
