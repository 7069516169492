import clsx from 'clsx'
import { Transaction } from 'modules/bank/types'
import { formatTransactionAmount } from 'modules/bank/utils/format/transactions'

interface Props {
  transaction: Pick<
    Transaction,
    'amount' | 'originalAmount' | 'currency' | 'originalCurrency'
  >
}

export const TransactionAmount = ({
  transaction: { amount, currency, originalCurrency, originalAmount },
}: Props) => {
  const amountFloat = parseFloat(amount)
  const originalAmountFloat = originalAmount ? parseFloat(originalAmount) : 0

  return (
    <div>
      <span
        className={clsx(
          'font-headings font-bold leading-5',
          amountFloat >= 0 ? 'text-success' : 'text-info-600'
        )}
      >
        {formatTransactionAmount(amountFloat, currency)}
      </span>
      {!!originalCurrency && originalCurrency !== currency && (
        <span className="block font-bold leading-5 text-info-400">
          {formatTransactionAmount(originalAmountFloat, originalCurrency)}
        </span>
      )}
    </div>
  )
}
