import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import {
  StatusIconsBgColors,
  VerticalProgressStepStatus,
} from '../VerticalProgressStep/utils'

interface Props {
  stepStatus: VerticalProgressStepStatus
  statusIconsBgColors: StatusIconsBgColors
}

export const VerticalProgressStepIconContainer: React.FC<
  PropsWithChildren<Props>
> = ({ stepStatus, statusIconsBgColors, children }) => (
  <span
    className={clsx(
      'flex h-9 w-9 items-center justify-center rounded-full',
      statusIconsBgColors[stepStatus]
    )}
  >
    {children}
  </span>
)
