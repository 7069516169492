import { VerticalProgressStepStatus } from '../components/VerticalProgressStepper/VerticalProgressStep/utils'

export const getStepStatus = (
  stepIndex: number,
  validatedStepNumber: number
) => {
  if (stepIndex < validatedStepNumber) {
    return VerticalProgressStepStatus.VALIDATED
  }
  if (stepIndex === validatedStepNumber) {
    return VerticalProgressStepStatus.IN_PROGRESS
  }
  return VerticalProgressStepStatus.TODO
}

export const useVerticalProgressStepStatus = (validatedStepNumber: number) => ({
  registerStepStatus: (stepIndex: number) => ({
    stepStatus: getStepStatus(stepIndex, validatedStepNumber),
  }),
})
