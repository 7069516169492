import clsx from 'clsx'
import { extractAmount } from 'modules/common/lib/helpers/amount'

export interface AmountProps {
  amount?: number
  className?: string
  integerClassName?: string
  decimalClassName?: string
  prefixClassName?: string
  suffixClassName?: string
  prefix?: string
  suffix?: string
  ignoreDecimals?: boolean
}

export const Amount = ({
  amount,
  className,
  integerClassName,
  decimalClassName,
  suffixClassName,
  prefixClassName,
  prefix,
  suffix,
  ignoreDecimals,
}: AmountProps) => {
  const { symbol, decimal, integer, sign } = extractAmount(amount)

  return (
    <span
      data-testid="Amount"
      className={clsx('whitespace-nowrap font-headings', className)}
    >
      {prefix && <span className={prefixClassName}>{prefix}</span>}
      <span className={integerClassName}>
        {sign === '-' && sign}
        {integer}
        {!ignoreDecimals && <span>,</span>}
      </span>
      <span className={decimalClassName}>
        {!ignoreDecimals && <span>{decimal}</span>}
        {symbol}
      </span>
      {suffix && <span className={suffixClassName}>{suffix}</span>}
    </span>
  )
}
