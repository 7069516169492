import {
  ContentModalProps,
  useContentModal,
} from '../useContentModal/useContentModal'
import {
  CtaModalFooter,
  CtaModalFooterProps,
} from './components/CtaModalFooter'
export interface CtaModalProps
  extends Omit<ContentModalProps, 'footer'>,
    CtaModalFooterProps {
  content: React.ReactNode
}

export const useCtaModal = ({
  content,
  onPrimaryClick,
  onSecondaryClick,
  primaryButtonText,
  primaryButtonDisabled,
  secondaryButtonText,
  primaryButtonColor,
  buttonsAlignment,
  hiddenOnError,
  ...props
}: CtaModalProps) => {
  return useContentModal({
    content,
    footer: (
      <CtaModalFooter
        onPrimaryClick={onPrimaryClick}
        onSecondaryClick={onSecondaryClick}
        primaryButtonText={primaryButtonText}
        primaryButtonDisabled={primaryButtonDisabled}
        secondaryButtonText={secondaryButtonText}
        primaryButtonColor={primaryButtonColor}
        buttonsAlignment={buttonsAlignment}
        hiddenOnError={hiddenOnError}
      />
    ),
    ...props,
  })
}
