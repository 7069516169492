import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUser.query'
import { useCorporateTDAEInformationQuery } from 'modules/services/queries/useCorporateTDAEInformation.query'
import { useCallback } from 'react'

export const useFetchTDAEInformation = () => {
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    refetch: refetchUser,
  } = useAuthenticatedUserQuery()

  const {
    data: tdaeInformation,
    isLoading: isTDAEInformationLoading,
    isError: isTDAEInformationError,
    refetch: refetchTDAEInformation,
    error: tdaeInformationError,
  } = useCorporateTDAEInformationQuery(user?.corporateId, {
    retry: false,
  })

  const isFetchTDAEInformationLoading =
    isUserLoading || isTDAEInformationLoading

  const isFetchTDAEInformationError = isUserError || isTDAEInformationError

  const errorRefetch = useCallback(() => {
    if (isUserError) {
      refetchUser()
    } else if (isTDAEInformationError) {
      refetchTDAEInformation()
    }
  }, [isUserError, isTDAEInformationError, refetchUser, refetchTDAEInformation])

  return {
    isFetchTDAEInformationLoading,
    isFetchTDAEInformationError,
    tdaeInformation,
    tdaeInformationError,
    errorRefetch,
  }
}
