// eslint-disable-next-line no-control-regex
export const rEmail = /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
export const rBic = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/
export const rAtLeastOneSpecialCharacter = /[!@#€£$%^&*)(+=._\-"'?;,:/°§<>[\] {}\\|¿¡~•¶§®©`™¥Δ√✓÷×]/
export const rWithAcceptedCharacters = /^[AÀÁÂÄaàáâäBbCÇcçDdEÉÊÈËeèéêëFfGgHhIÍÎÌÏiìíîïJjKkLlMmNnOÒÓÔÖoòóôöPpQqRrSsTtUÙÚÛÜuùúûüVvWwXxYÝŸyýÿZz\-'’æœÆŒ `]+$/g
export const rPhone = /^(0?[1-9]){1}([0-9]{2}){4}$/
export const rFrenchPhone = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
export const rYear = /^[0-9]{4}$/
export const rZipCode = /^\d{5}$/

export const rAtLeastOneDigit = /\d/
export const rAtLeastOneUppercase = /[A-Z]/
export const rAtLeastOneLowercase = /[a-z\u00E0-\u00FC]/
export const rAllDigits = /\d+/
