import { Transaction, TransactionPaymentMethod } from 'modules/bank/types'
import clsx from 'clsx'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'
import {
  IconCardDeposit,
  IconCardWithdraw,
  IconCashDeposit,
  IconCashWithdraw,
} from 'modules/common/components/_icons/icons'

interface Props {
  transaction: Transaction
  className?: string
}

export const TransactionIcon = ({
  transaction: { amount, paymentMethod },
  className,
}: Props) => {
  const isNegative = parseFloat(amount) < 0

  const isCard = paymentMethod === TransactionPaymentMethod.CARD

  const isCashDeposit = !isNegative && !isCard

  const isCashWithdraw = isNegative && !isCard

  const isCardDeposit = !isNegative && isCard

  if (isCashDeposit) {
    return (
      <IconCashDeposit className={clsx('h-6 w-6 text-success ', className)} />
    )
  } else if (isCashWithdraw) {
    return (
      <IconCashWithdraw className={clsx('h-6 w-6 text-info-600', className)} />
    )
  } else if (isCardDeposit) {
    return (
      <IconCardDeposit className={clsx('h-6 w-6 text-success ', className)} />
    )
  } else {
    return (
      <IconCardWithdraw className={clsx('h-6 w-6 text-info-600', className)} />
    )
  }
}

export const TransactionIconSkeleton = ({
  className,
}: {
  className: string
}) => <Skeleton className={clsx('h-6 w-6 rounded-sm', className)} />
