import clsx from 'clsx'
import { isString } from 'lodash'
import { RecursivePartial } from 'modules/common/types/utils'
import { PropsWithChildren } from 'react'
import { VerticalProgressStepIconContainer } from '../VerticalProgressStepIconContainer/VerticalProgressStepIconContainer'
import { VerticalProgressStepStatus } from './utils'
import styles from './VerticalProgressStep.module.css'
import {
  useVerticalProgressStepTheme,
  VerticalProgressStepTheme,
} from './VerticalProgressStep.theme'

interface Props {
  title: string | React.ReactNode
  stepStatus: VerticalProgressStepStatus
  theme?: RecursivePartial<VerticalProgressStepTheme>
}

export const VerticalProgressStep: React.FC<PropsWithChildren<Props>> = ({
  title,
  stepStatus,
  theme,
  children,
}) => {
  const {
    statusIcons,
    statusIconsBgColors,
    dividerStatusColors,
    titleStatusColors,
  } = useVerticalProgressStepTheme(theme)

  return (
    <div
      className={clsx('step-container flex space-x-4', styles.stepContainer)}
    >
      <div className="flex flex-col items-center space-y-1">
        <VerticalProgressStepIconContainer
          stepStatus={stepStatus}
          statusIconsBgColors={statusIconsBgColors}
        >
          {statusIcons[stepStatus]}
        </VerticalProgressStepIconContainer>
        <span
          className={clsx(
            'flex-1 rounded-full border',
            dividerStatusColors[stepStatus],
            styles.stepDivider
          )}
        />
      </div>
      <div className="flex-1 text-left">
        <div className={titleStatusColors[stepStatus]}>
          {isString(title) ? (
            <h5 className="mb-1 py-2 font-default text-base font-bold leading-5">
              {title}
            </h5>
          ) : (
            title
          )}
        </div>
        {children}
      </div>
    </div>
  )
}
