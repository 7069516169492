import { extractAmount } from 'modules/common/lib/helpers/amount'

interface FormatDocumentPriceProps {
  price: number
  taxed?: boolean
}

export const formatDocumentPrice = ({
  price,
  taxed = false,
}: FormatDocumentPriceProps) => {
  const { integer, decimal, symbol } = extractAmount(price)

  return `${integer},${decimal} ${symbol} ${taxed ? 'TTC' : 'HT'}`
}

export const decimalPrice = (rowPrice?: number) => {
  return rowPrice ? rowPrice / 100 : 0
}
