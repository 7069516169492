import { IconCheck, IconThreeDots } from '../../_icons/icons'

export enum VerticalProgressStepStatus {
  VALIDATED = 'VALIDATED',
  IN_PROGRESS = 'IN_PROGRESS',
  TODO = 'TODO',
}

export type StatusIcons = Record<VerticalProgressStepStatus, React.ReactNode>
export type StatusIconsBgColors = Record<VerticalProgressStepStatus, string>
export type TitleStatusColors = Record<VerticalProgressStepStatus, string>
export type DividerStatusColors = Record<VerticalProgressStepStatus, string>

export const defaultStatusIcons: StatusIcons = {
  [VerticalProgressStepStatus.VALIDATED]: <IconCheck className="text-white" />,
  [VerticalProgressStepStatus.IN_PROGRESS]: (
    <IconThreeDots className="text-white" />
  ),
  [VerticalProgressStepStatus.TODO]: <IconThreeDots className="text-white" />,
}

export const defaultStatusIconsBgColors: StatusIconsBgColors = {
  [VerticalProgressStepStatus.VALIDATED]: 'bg-success ',
  [VerticalProgressStepStatus.IN_PROGRESS]: 'bg-primary',
  [VerticalProgressStepStatus.TODO]: 'bg-info-300',
}

export const defaultDividerStatusColors: DividerStatusColors = {
  [VerticalProgressStepStatus.VALIDATED]: 'border-success ',
  [VerticalProgressStepStatus.IN_PROGRESS]: 'border-primary',
  [VerticalProgressStepStatus.TODO]: 'border-info-300',
}

export const defaultTitleStatusColors: TitleStatusColors = {
  [VerticalProgressStepStatus.VALIDATED]: 'text-info-600',
  [VerticalProgressStepStatus.IN_PROGRESS]: 'text-primary',
  [VerticalProgressStepStatus.TODO]: 'text-info-300',
}
