import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export const VerticalProgressStepper: React.FC<
  PropsWithChildren<{ className?: string }>
> = ({ className, children }) => {
  return (
    <div className={clsx('flex flex-col space-y-1', className)}>{children}</div>
  )
}
