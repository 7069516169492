import clsx from 'clsx'
import { formatDocumentPrice } from 'modules/invoicing/lib/format/price'

export interface DocumentPriceProps {
  price: number
  taxed?: boolean
  className?: string
}

export const DocumentPrice = ({
  price,
  taxed,
  className,
}: DocumentPriceProps) => {
  return (
    <span className={clsx('font-headings font-bold text-info-600', className)}>
      {formatDocumentPrice({ price, taxed })}
    </span>
  )
}
