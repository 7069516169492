import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { UseQueryOptions } from 'react-query'
import axios, { AxiosError } from 'axios'
import { EnumServicesQueryKeys } from './keys'
import { CorporateTDAEInformation } from '../types/tdae'

interface FetchCorporateTDAEInformationProps {
  corporateId: string
  accessToken: string
}

const fetchCorporateTDAEInformation = async ({
  corporateId,
  accessToken,
}: FetchCorporateTDAEInformationProps) => {
  const { data } = await axios.get<CorporateTDAEInformation>(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/bservices/${corporateId}/tdae/info`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )

  return data
}

export const useCorporateTDAEInformationQuery = (
  corporateId?: string,
  queryOptions?: UseQueryOptions<CorporateTDAEInformation, AxiosError>
) => {
  return useQueryWithAuth<CorporateTDAEInformation, AxiosError>(
    [EnumServicesQueryKeys.CORPORATE_TDAE_INFORMATION, corporateId],
    async (context, accessToken) => {
      if (!corporateId) {
        throw new Error(
          'useCorporateTDAEInformationQuery: Missing parameter corporateId'
        )
      }

      return fetchCorporateTDAEInformation({
        accessToken,
        corporateId,
      })
    },
    {
      enabled: !!corporateId,
      ...queryOptions,
    }
  )
}
