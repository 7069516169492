export interface Amount {
  integer: string
  decimal: string
  symbol: string
  sign: string
}

const numberWithSpaces = (int: number) => {
  return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

const CURRENCIES = {
  USD: '$',
  GBP: '£',
  EUR: '€',
} as const

export const extractAmount = (number = 0, currency = 'EUR'): Amount => {
  const absoluteNumber = Math.abs(number)
  const decimal = absoluteNumber.toFixed(2)
  const integer = numberWithSpaces(Math.trunc(parseInt(decimal)))

  return {
    symbol: CURRENCIES[currency] || currency,
    integer,
    decimal: decimal.substring(decimal.indexOf('.') + 1),
    sign: number < 0 ? '-' : '+',
  }
}

export const formatNumberWithCurrency = (
  number = 0,
  options?: Intl.NumberFormatOptions
) =>
  number.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    ...options,
  })
