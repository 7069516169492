import clsx from 'clsx'
import { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
import { HelperText } from '../../HelperText/HelperText'

export interface RadioButtonProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'label'> {
  label: string | React.ReactNode
  labelClassName?: string
  error?: FieldError
  dataTestId?: string
}

export const RadioButton: React.FC<RadioButtonProps> = forwardRef(
  (
    { id, label, error, className, labelClassName, dataTestId, ...inputProps },
    ref
  ) => {
    return (
      <div className={clsx('relative', className)} data-testid={dataTestId}>
        <label className="inline-flex items-center" htmlFor={id}>
          <input
            {...inputProps}
            ref={ref}
            id={id}
            type="radio"
            className="border-2 border-info-400 text-primary checked:border-primary-600"
          />
          <span
            className={clsx(
              'ml-2 text-info-400 checked-sibling:text-info-600',
              labelClassName
            )}
          >
            {label}
          </span>
        </label>
        {!!error?.message && <HelperText color="danger" text={error.message} />}
      </div>
    )
  }
)
