import { RecursivePartial } from 'modules/common/types/utils'
import {
  defaultDividerStatusColors,
  defaultStatusIcons,
  defaultStatusIconsBgColors,
  defaultTitleStatusColors,
  DividerStatusColors,
  StatusIcons,
  StatusIconsBgColors,
  TitleStatusColors,
} from './utils'

export interface VerticalProgressStepTheme {
  statusIcons: StatusIcons
  statusIconsBgColors: StatusIconsBgColors
  dividerStatusColors: TitleStatusColors
  titleStatusColors: DividerStatusColors
}

export const useVerticalProgressStepTheme = ({
  statusIcons,
  statusIconsBgColors,
  dividerStatusColors,
  titleStatusColors,
}: RecursivePartial<VerticalProgressStepTheme> = {}): VerticalProgressStepTheme => ({
  statusIcons: {
    ...defaultStatusIcons,
    ...statusIcons,
  },
  statusIconsBgColors: {
    ...defaultStatusIconsBgColors,
    ...statusIconsBgColors,
  },
  dividerStatusColors: {
    ...defaultDividerStatusColors,
    ...dividerStatusColors,
  },
  titleStatusColors: {
    ...defaultTitleStatusColors,
    ...titleStatusColors,
  },
})
